<p>Configurar</p>
<div class="row" style="height: 320px">
  <div class="col-md-3">
    <span [style.background]="selectedColor" [cpToggle]="true" [cpDialogDisplay]="'inline'"
      [cpCancelButton]="false" [cpOKButton]="true" [cpCancelButtonClass]="'btn btn-primary btn-xs'"
      [cpAlphaChannel]="'disabled'"
      [(colorPicker)]="selectedColor" (colorPickerSelect)="changeColor($event)"></span>
  </div>
</div>
<br>
