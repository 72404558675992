<h2 mat-dialog-title *ngIf="itemData.epigrafeId == 17">{{itemData.nombre}}<span style="font-size: 12px; padding-left: 10px;"
    *ngIf="itemData.epigrafe">{{itemData.epigrafe.codigo}} -
    {{itemData.epigrafe.nombre}}</span></h2>
<h2 mat-dialog-title *ngIf="itemData.epigrafeId == 4">{{itemData.descripcion}}<span style="font-size: 12px; padding-left: 10px;"
      *ngIf="itemData.epigrafe">{{itemData.epigrafe.codigo}} -
      {{itemData.epigrafe.nombre}}</span></h2>
<form [formGroup]="updateForm">
  <mat-form-field appearance="fill">
    <mat-label>Num. Orden</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'n_orden'" [readonly]="!rolPermitir">
  </mat-form-field>

  <!--
  <mat-form-field appearance="fill">
    <mat-label>Denominación</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'denominacion'">
  </mat-form-field>
-->
  <mat-form-field appearance="fill">
    <mat-label>Ref. Catastral</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'referencia_catastral'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="itemData.epigrafeId==4">
    <mat-label>Tipo de via</mat-label>
    <mat-select [formControlName]="'tipoViaId'" *ngIf="rolPermitir">
      <mat-option *ngFor="let item of tiposVias" [value]="item.id">
        {{item.nombre}}</mat-option>
    </mat-select>
    <input matInput placeholder="NO CONSTA" [value]="nombreTipoVia" [readonly]="!rolPermitir" *ngIf="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="itemData.epigrafeId==17">
    <mat-label>Paraje</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'paraje'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="itemData.epigrafeId!=4">
    <mat-label>Polígono</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'poligono'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="itemData.epigrafeId!=4">
    <mat-label>Parcela</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'parcela'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="itemData.epigrafeId==4">
    <mat-label>Vía Pública</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'via_publica'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="itemData.epigrafeId==4">
    <mat-label>Número</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'numero'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Naturaleza Dominio</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'naturaleza'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Aprovechamiento</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'aprovechamiento'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Título Propiedad</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'titulo_propiedad'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Finca Registral</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'finca'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Sup. Registral (m2)</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'s_sup_registral'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Sup. Catastral (m2)</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'s_sup_catastral'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Fecha Adquisición</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'s_fecha_adquisicion'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Valor Adquisición</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'s_valor_adquisicion'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Estado mueble</mat-label>
    <mat-select [formControlName]="'estado_mueble'" *ngIf="rolPermitir">
      <mat-option value="PROPIEDAD">
        En Propiedad
      </mat-option>
      <mat-option value="CEDIDOS">
        Cedido
      </mat-option>
      <mat-option value="ADSCRITO">
        Adscrito
      </mat-option>
    </mat-select>
    <input matInput placeholder="Simple placeholder" [value]="nombreEstadoMueble" [readonly]="!rolPermitir" *ngIf="!rolPermitir">
  </mat-form-field>

  <mat-dialog-actions align="end">
    <button mat-button (click)="actualizarPoligono()" *ngIf="rolPermitir">Actualizar Polígono</button>
    <button mat-button (click)="editar()" *ngIf="rolPermitir">Editar</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Cerrar</button>
  </mat-dialog-actions>

</form>
