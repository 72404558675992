<h2 mat-dialog-title>Agregar</h2>
<form [formGroup]="nuevoForm" (ngSubmit)="guardar()" autocomplete="off">
  <mat-dialog-content class="mat-typography">
    <!-- <mat-form-field appearance="fill">
    <mat-label>N. Orden</mat-label>
    <input matInput placeholder="Simple placeholder">
  </mat-form-field> -->
    <mat-form-field appearance="fill">
      <mat-label>Epigrafe</mat-label>
      <!-- <input matInput placeholder="Simple placeholder"> -->
      <mat-select [formControlName]="'epigrafeId'">
        <ng-container *ngFor="let group of listaEpigrafes">
          <mat-optgroup [label]="group.codigo + ' ' + group.nombre" *ngIf="group.subepigrafes.length">
            <!-- [disabled]="group.disabled" -->
            <ng-container *ngFor="let pokemon of group.subepigrafes">
              <mat-optgroup [label]="pokemon.codigo + ' ' + pokemon.nombre" *ngIf="!pokemon.subepigrafes.length">
                <mat-option [value]="pokemon.id" *ngIf="!pokemon.subepigrafes.length">
                  {{pokemon.codigo}} - {{pokemon.nombre}}
                </mat-option>
              </mat-optgroup>
              <ng-container *ngIf="pokemon.subepigrafes.length">
                <mat-optgroup [label]="pokemon.codigo + ' ' + pokemon.nombre">
                  <mat-option *ngFor="let el of pokemon.subepigrafes" [value]="el.id">
                    {{el.codigo}} - {{el.nombre}}
                  </mat-option>
                </mat-optgroup>
              </ng-container>
            </ng-container>
          </mat-optgroup>
          <mat-option [value]="group.id" *ngIf="!group.subepigrafes.length">
            {{group.codigo}} - {{group.nombre}}
          </mat-option>
        </ng-container>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Número</mat-label>
      <input matInput placeholder="Simple placeholder" [formControlName]="'numero'">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Sign. Archivo</mat-label>
      <input matInput placeholder="Simple placeholder" [formControlName]="'sign_archivo'">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Contable</mat-label>
      <mat-select [formControlName]="'contableCodigo'">
        <mat-option *ngFor="let item of listaContable" [value]="item.codigo">{{item.codigo}} -
          {{item.nombre}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Jurídica</mat-label>
      <mat-select [formControlName]="'juridicaCodigo'">
        <mat-option *ngFor="let item of listaJuridica" [value]="item.codigo">{{item.codigo}} -
          {{item.nombre}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Funcional</mat-label>
      <mat-select [formControlName]="'funcionalCodigo'">
        <mat-option *ngFor="let item of listaFuncional" [value]="item.codigo">{{item.codigo}} -
          {{item.nombre}}</mat-option>
      </mat-select>
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Estado del mueble</mat-label>
      <mat-select [formControlName]="'estado_mueble'">
        <mat-option value="PROPIEDAD">
          En Propiedad
        </mat-option>
        <mat-option value="CEDIDOS">
          Cedido
        </mat-option>
        <mat-option value="ADSCRITO">
          Adscrito
        </mat-option>
      </mat-select>
    </mat-form-field>


    <mat-form-field appearance="fill">
      <mat-label>Referencia</mat-label>
      <input matInput placeholder="Simple placeholder" [formControlName]="'referencia_catastral'">
    </mat-form-field>

    <mat-form-field appearance="fill">
      <mat-label>Tipo de via</mat-label>
      <mat-select [formControlName]="'tipoviaId'">
        <mat-option *ngFor="let item of tiposVias" [value]="item.id">
          {{item.nombre}}</mat-option>
      </mat-select>
    </mat-form-field>

    <!-- <mat-form-field appearance="fill">
      <mat-label>C. Superficie Catastral</mat-label>
      <input matInput placeholder="Simple placeholder" [formControlName]="'tipoviaId'">
    </mat-form-field> -->

    <mat-form-field appearance="fill">
      <mat-label>Naturaleza</mat-label>
      <input matInput placeholder="Simple placeholder" [formControlName]="'naturaleza'">
    </mat-form-field>

  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <button mat-button mat-dialog-close>Cerrar</button>
    <button type="submit" mat-button cdkFocusInitial>Guardar</button>
  </mat-dialog-actions>
</form>
