<mat-dialog-content class="mat-typography">
  <mat-tab-group>
    <mat-tab label="Información">
      <ng-container *ngIf="itemData">
        <p *ngIf="statusGeom"><b>Polígono actualizado.</b><br>Por favor presione el botón EDITAR para guardar los
          cambios.<br><span *ngIf="statusGeom && !newGeom" style="color: red"><b>Polígano VACÍO</b></span></p>
        <ng-container *ngIf="itemData.epigrafeId == 18">
          <app-calles [itemData]="itemData" (updatePoligono)="updatePoligono($event)"></app-calles>
        </ng-container>
        <ng-container *ngIf="itemData.epigrafeId == 19">
          <app-caminos [itemData]="itemData" (updatePoligono)="updatePoligono($event)"></app-caminos>
        </ng-container>
        <ng-container *ngIf="[8, 9, 10, 11, 20].includes(itemData.epigrafeId)">
          <app-inmuebles-urbanos [itemData]="itemData" (updatePoligono)="updatePoligono($event)"
            (successUpdate)="successUpdate($event)"></app-inmuebles-urbanos>
        </ng-container>
        <ng-container *ngIf="[4, 17].includes(itemData.epigrafeId)">
          <app-inmuebles-rusticos [itemData]="itemData"
            (updatePoligono)="updatePoligono($event)" (successUpdate)="successUpdate($event)"></app-inmuebles-rusticos>
        </ng-container>
      </ng-container>
    </mat-tab>

    <mat-tab label="Archivos">
      <ng-container *ngIf="itemData">
        <app-home [item]="itemData"></app-home>
      </ng-container>
    </mat-tab>
  </mat-tab-group>
</mat-dialog-content>
