import { Component, OnInit, Inject } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { FeatureService } from '../../services/feature.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from 'src/app/auth/auth.service';

@Component({
  selector: 'app-modal-nuevo',
  templateUrl: './modal-nuevo.component.html',
  styleUrls: ['./modal-nuevo.component.scss'],
})
export class ModalNuevoComponent implements OnInit {
  idMunicipio = -1;
  nuevoForm: UntypedFormGroup = this._formBuilder.group({
    epigrafeId: [null, [Validators.required, Validators.min(1)]],
    numero: [null],
    sign_archivo: [null, Validators.required],
    juridicaCodigo: [null, Validators.required],
    contableCodigo: [null, Validators.required],
    funcionalCodigo: [null, Validators.required],
    estado_mueble: ['PROPIEDAD', Validators.required],
    referencia_catastral: [''],
    tipoviaId: [null, Validators.required],
    naturaleza: [''],
    municipioId: [0],
  });

  constructor(
    private _formBuilder: UntypedFormBuilder,
    @Inject(MAT_DIALOG_DATA) public itemData: any,
    private dialogRef: MatDialogRef<ModalNuevoComponent>,
    private featureService: FeatureService,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
  ) {
  }

  listaContable: any[] = [];
  listaJuridica: any[] = [];
  listaFuncional: any[] = [];
  listaEpigrafes: any[] = [];
  tiposVias: any[] = [];
  ngOnInit(): void {
    this.idMunicipio = this.authService.idMunicipio;
    console.log(this.itemData);
    this.featureService.getContable().subscribe((resp) => {
      this.listaContable = resp.result;
    });

    this.featureService.getJuridica().subscribe((resp) => {
      this.listaJuridica = resp.result;
    });
    this.featureService.getFuncional().subscribe((resp) => {
      this.listaFuncional = resp.result;
    });

    this.featureService.getTiposVias().subscribe((resp) => {
      this.tiposVias = resp.result;
    });

    this.featureService.getEpigrafes().subscribe((resp) => {
      this.listaEpigrafes = resp.result;
      this.listaEpigrafes = this.listaEpigrafes.filter(
        (e) => !this.epl.includes(e.id)
      );
      this.listaEpigrafes.sort((a, b) => {
        if (a.codigo > b.codigo) {
          return 1;
        }
        if (a.codigo < b.codigo) {
          return -1;
        }
        return 0;
      });
      this.listaEpigrafes.map((e) => {
        if (e.subepigrafes.length) {
          e.subepigrafes.sort((a: any, b: any) => {
            if (a.codigo > b.codigo) {
              return 1;
            }
            if (a.codigo < b.codigo) {
              return -1;
            }
            return 0;
          });
          e.subepigrafes.map((e2: any) => {
            if (e2.subepigrafes.length) {
              e2.subepigrafes.sort((a: any, b: any) => {
                if (a.codigo > b.codigo) {
                  return 1;
                }
                if (a.codigo < b.codigo) {
                  return -1;
                }
                return 0;
              });
            }
          });
        }
      });
    });
  }

  epl = [12, 7, 13, 14, 21, 5, 6];

  guardar() {
    if (this.nuevoForm.invalid) {
      this.nuevoForm.markAllAsTouched();
      return;
    }
    const formValue = this.nuevoForm.value;
    formValue.geom = this.itemData;
    formValue.municipioId = this.idMunicipio;
    this.featureService.create(formValue).subscribe(
      (data) => {
        this.nuevoForm.reset();
        // this.loading = false;
        // this.inicializarForm();
        this._snackBar.open('Solicitud de alta de bien, generada con éxito!!', 'Cerrar', {
          duration: 2000,
        });
        this.dialogRef.close({
          event: 'successNuevo',
          data: {
            status: true,
            itemData: data.result,
          },
        });
      },
      (error) => {
        // this.loading = false;
        this._snackBar.open('Error al guardar', 'Cerrar', {
          duration: 2000,
        });
      }
    );
  }
}
