import { Component, OnInit, Inject, Output, EventEmitter } from '@angular/core';
import { AbstractControl, FormControl, Validators } from '@angular/forms';
import { MAT_BOTTOM_SHEET_DATA, MatBottomSheetRef } from '@angular/material/bottom-sheet';
import { ThemePalette } from '@angular/material/core';

@Component({
  selector: 'app-configbottom-sheet',
  templateUrl: './configbottom-sheet.component.html',
  styles: [`.ngx-color-slider.alpha {
    display: none;
  }`],
})
export class ConfigbottomSheetComponent implements OnInit {
  
  color: any;
  public touchUi = false;
  public disabled = false;
  colorCtr: AbstractControl = new FormControl(null);
  public arrayColors: any = {
    color1: '#2883e9',
    color2: '#e920e9',
    color3: 'rgb(255,245,0)',
    color4: 'rgb(236,64,64)',
    color5: 'rgba(45,208,45,1)',
  };
  public selectedColor: string = '#2883e9';
  @Output() variableEmitida: EventEmitter<any> = new EventEmitter<any>();

  constructor(@Inject(MAT_BOTTOM_SHEET_DATA) public data: any, 
  private bottomSheetRef: MatBottomSheetRef<ConfigbottomSheetComponent>) {
    console.log(data);
    this.selectedColor = data;

  }



  ngOnInit(): void {
    console.log(this.data);
  }

  changeColor(event: any) {
    console.log(event);
    this.variableEmitida.emit(event);
    this.bottomSheetRef.dismiss();
  }
}
