import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './pages/login/login.component';
import { RouterModule, Routes } from '@angular/router';
import { AuthService } from './auth.service';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './auth.interceptor';
import { MapaComponent } from '../pages/mapa/mapa.component';

const routes: Routes = [
  { path: 'auth-token/:token', component: LoginComponent },
];

@NgModule({
  declarations: [LoginComponent],
  imports: [RouterModule.forChild(routes), CommonModule],
  exports: [LoginComponent],
  providers: [
    AuthService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true,
    },
  ],
})
export class AuthModule {}
