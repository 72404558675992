import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'noConsta',
})
export class NoConstaPipe implements PipeTransform {
  transform(value: string): string {
    console.log(value);
    if (value && value.trim().length > 0) {
      return value;
    } else {
      return 'NO CONSTA';
    }
  }
}
