<div class="grid grid-cols-1 sm:grid-cols-2 gap-6 mt-4">
  <div style="padding-top: 20px;">
    <div class="flex gap-2" style="width: 600px;">
      <mat-grid-list cols="3" rowHeight="400px">
        <mat-grid-tile [colspan]="2" [rowspan]="1">

          <div class="w-3/5">

            <ngx-skeleton-loader count="1" appearance="circle" [theme]="{
                      width: '200px',
                      height: '110px',
                      'border-radius': '10px'
                    }" *ngIf="loadingImage"></ngx-skeleton-loader>
            <ng-container *ngIf="image_selected">
              <div *ngIf="!loadingImage">
                <mat-card>
                  <img style="width: 100%;" [src]="image_selected.data" alt="img-registro">
                  <button mat-icon-button aria-label="Example icon button with a vertical three dot icon"
                    (click)="onPrev()">
                    <mat-icon>west</mat-icon>
                  </button>
                  <button mat-icon-button color="primary" aria-label="Example icon button with a home icon"
                    (click)="onNext()">
                    <mat-icon>east</mat-icon>
                  </button>
                </mat-card>
              </div>
              <div class="flex justify-center gap-5 mt-3">

              </div>
            </ng-container>
          </div>
        </mat-grid-tile>
        <mat-grid-tile [colspan]="1" [rowspan]="1">
          <div *ngIf="image_selected">
            <div>Datos</div>
            <div>
              <b>Nombre del fichero:</b>
              <div>{{ image_selected.name }}</div>
            </div>
            <b>Tipo:</b>
            <div class="font-bold leading-4 text-[#A4A9AD]">{{ image_selected.tipo }}</div>
          </div>
        </mat-grid-tile>

      </mat-grid-list>
    </div>
    <h3>
      Listado de ficheros
      <span
      (click)="onOpenFile()">
      <input type="file" (change)="onFileChange($event)" #inputFile hidden>
      <div  style="cursor: pointer; display: contents;">
        <mat-icon>add</mat-icon>
      </div>
    </span>
  </h3>
    <hr>

    <div class="overflow-auto" *ngIf="files.length">
      <table class="w-full bg-transparent rounded-xl">
          <thead class="bg-[#333B63] text-[#FBFCFD]">
              <th>Tipo</th>
              <th>Nombre</th>
              <th>Acciones</th>
          </thead>
          <tbody>
              <tr *ngFor="let item of files; let i=index">
                  <td>
                      <mat-select required [value]="item.tipo" id="tipoFile{{i}}" (selectionChange)="changeTipoFile($event, i)">
                          <mat-option value="docs">Documento</mat-option>
                          <mat-option value="fotografias">Fotografía</mat-option>
                          <mat-option value="planos">Plano</mat-option>
                      </mat-select>
                  </td>
                  <td class="text-center">{{ item.file.name }}</td>
                  <td class="flex flex-row items-center justify-center" *ngIf="item.status == 0 || item.status == -1">
                      <p *ngIf="item.status == -1" class="text-red-600 text-xs">Error</p>
                      <button mat-icon-button color="warn" (click)="onRemoveFile(i)">
                          <mat-icon>delete</mat-icon>
                      </button>
                      <button mat-icon-button color="primary" (click)="uploadFile(i)">
                          <mat-icon>upload</mat-icon>
                      </button>
                  </td>
                  <td class="flex flex-row flex-auto w-full items-center justify-center" *ngIf="item.status == 1">
                      <mat-progress-bar class="w-5/6 ml-2" mode="determinate" [value]="item.uploadProgress"
                          ></mat-progress-bar>
                      <mat-icon class="w-auto cursor-pointer" (click)="cancelUpload(i)"
                          >delete_forever</mat-icon>
                  </td>
                  <td class="content-center text-green-600 font-bold text-xs" *ngIf="item.status == 2">
                      OK
                  </td>
              </tr>
          </tbody>
      </table>
  </div>

    <div>
      <table class="w-full bg-transparent rounded-xl">
        <thead class="bg-[#333B63] text-[#FBFCFD]">
          <th class="py-2">Tipo</th>
          <th class="py-2">Nombre</th>
          <th class="py-2">Acciones</th>
        </thead>
        <tbody>
          <tr *ngFor="let image of images; let i=index">
            <td class="text-center py-1">{{ image.tipo }}</td>
            <td class="text-center py-1">{{ image.name }}</td>
            <td class="flex justify-center py-1">
              <button mat-raised-button color="primary"
                (click)="descargarArchivo(image)"><mat-icon>visibility</mat-icon> Ver fichero</button>
            </td>
          </tr>
          <tr *ngFor="let file of documentos; let i=index">
            <td class="text-center py-1">{{ file.tipo }}</td>
            <td class="text-center py-1">{{ file.name }}</td>
            <td class="flex justify-center py-1">
              <button mat-raised-button color="primary" (click)="descargarArchivo(file)"><mat-icon>visibility</mat-icon>
                Ver fichero</button>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</div>
