import { Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import {
  UntypedFormBuilder,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { FeatureService } from '../../../../services/feature.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { Subscription, catchError, throwError, finalize } from 'rxjs';
import { HttpEventType, HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  documentos: any[] = [];
  images: any[] = [];
  loadingImage = false;
  index: number = 0;
  image_selected: any = this.images[this.index];
  loading = false;
  @Input() item: any;
  constructor(
    private featureService: FeatureService,
    private _snackBar: MatSnackBar,
    private _http: HttpClient
  ) {}

  ngOnInit(): void {
    this.loading = true;
    this.cargarAdjuntos();
  }

  descargarArchivo(archivo: any) {
    // if (archivo.tipo == 'DOCUMENTO') {
    let path = archivo.src.replace('manager/file', 'manager2/file2');
    archivo.src = path;
    // window.open(path, '_blank');
    // return;
    // }
    this.featureService.getFileUrl(archivo.src).subscribe((data) => {
      const url = window.URL.createObjectURL(data);
      window.open(url);
    });
  }

  cargarAdjuntos() {
    if (this.item.sign_archivo && this.item.sign_archivo.trim() != '') {
      this.consultarArchivo('f');
      this.consultarArchivo('c');
      this.consultarArchivo('d');
    }
  }

  consultarArchivo(tipo: any) {
    this.featureService
      .getPathFiles(tipo, this.item.sign_archivo, this.item.n_orden)
      .subscribe((data) => {
        if (tipo != 'd') {
          this.images = [...this.images, ...data];
          this.image_selected = this.images[0];
          if (this.image_selected)
            this.cargarImagen(this.image_selected.src ?? '');
        } else {
          this.documentos = data;
        }
      });
  }

  cargarImagen(url: any) {
    if (this.loadingImage) return;
    this.loadingImage = true;
    this.featureService.getDataImage(url).subscribe(
      (imgData: any) => {
        this.loadingImage = false;
        this.image_selected.data = imgData;
      },
      (err) => {
        this.loadingImage = false;
        console.log(err);
      }
    );
  }

  onNext(): void {
    if (this.loadingImage) return;
    this.index++;
    if (this.index >= this.images.length) this.index = 0;
    this.image_selected = this.images[this.index];
    this.cargarImagen(this.image_selected.src);
  }

  onPrev(): void {
    if (this.loadingImage) return;
    this.index--;
    if (this.index < 0) this.index = this.images.length - 1;
    this.image_selected = this.images[this.index];
    this.cargarImagen(this.image_selected.src);
  }

  files: any[] = [];
  @ViewChild('inputFile') inputFile?: ElementRef;
  onFileChange(event: any) {
    let file = event.target.files[0];
    this.files.push({
      tipo: 'docs',
      error: false,
      uploadSub: Subscription,
      uploadProgress: 0,
      file: file,
      status: 0,
    });
  }

  onOpenFile(): void {
    this.inputFile!.nativeElement.click();
  }

  uploadFile(i: number) {
    const allowedTypes = [
      'image/jpeg',
      'image/png',
      'application/pdf',
      'application/PDF',
    ];
    const file = this.files[i].file;
    // // Return if the file is not allowed
    if (!allowedTypes.includes(file.type)) {
      return;
    }

    const formData = new FormData();
    formData.append('documents', file);

    this.postImage(formData, i);
  }

  urlApiFiles = 'https://backend.dev.patrimoniogest.serprofes.com/api/';
  postImage(formData: FormData, i: number) {
    this.files[i].status = 1;
    const path = this.files[i].tipo;
    console.log(this.urlApiFiles);
    const upload$ = this._http
      // .post(`${this.urlApiFiles}file-manager/file/arrecife/${path}`, formData, {
      .post(`${this.urlApiFiles}file-manager/file/provincias/${this.featureService.dataMunicipio.provincia.nombre}/${this.featureService.dataMunicipio.nombre}/${path}`, formData, {
        reportProgress: true,
        observe: 'events',
      })
      .pipe(
        catchError((e) => {
          this.files[i].error = true;
          return throwError(e);
        }),
        finalize(() => {
          if (!this.files[i].error) {
            this.files[i].status = 2;
          }
        })
      );

    this.files[i].uploadSub = upload$.subscribe(
      (event: any) => {
        if (event.type == HttpEventType.UploadProgress) {
          this.files[i].uploadProgress = Math.round(
            100 * (event.loaded / event.total)
          );
        }
      },
      (error) => {
        this.files[i].status = -1;
        this._snackBar.open(
          'Error al subir archivo. | ' + error.message,
          'Cerrar',
          { duration: 2000 }
        );
      }
    );
  }

  changeTipoFile(event: any, i: number) {
    this.files[i].tipo = event.value;
  }

  cancelUpload(i: number) {
    this.files[i].uploadSub.unsubscribe();
    this.files[i].status = 0;
    this.files[i].uploadProgress = 0;
  }

  onRemoveFile(index: number) {
    this.files.splice(index, 1);
  }
}
