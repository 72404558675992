<ng-container *ngIf="!mostrarMapa">
  <app-login></app-login>
</ng-container>

<ng-container *ngIf="mostrarMapa">



  <mgl-map id="map" [style]="style" [zoom]="[6]" [center]="center" (load)="onLoadMap($event)">
    <mgl-control mglScale unit="metric" position="top-right"></mgl-control>
    <mgl-control mglNavigation position="bottom-right"></mgl-control>

    <mgl-control *ngIf="rolPermitirAgregar && !actualizarPoligono">
      <button mat-fab [color]="addStyle" (click)="agregar()">
        {{addText}}
      </button>
    </mgl-control>
    <mgl-control *ngIf="actualizarPoligono">
      <button mat-fab [color]="addStyle" (click)="finUpdatePoligono()">
        Finalizar
      </button>
    </mgl-control>
  </mgl-map>

  <div class="map-overlay">

    <mat-form-field appearance="fill">
      <mat-label>Estilo</mat-label>
      <mat-select [(value)]="layerId" (selectionChange)="changeStyle($event.value)">
        <mat-option value="basic">Default</mat-option>
        <mat-option value="streets">Streets</mat-option>
        <mat-option value="bright">Bright</mat-option>
        <mat-option value="light">Light</mat-option>
        <mat-option value="dark">Dark</mat-option>
        <mat-option value="satellite">Satélite</mat-option>
        <mat-option value="satellite-streets">Satellite Streets</mat-option>
      </mat-select>
    </mat-form-field>


    <button mat-icon-button [matMenuTriggerFor]="menu" aria-label="Example icon-button with a menu">
      <mat-icon>filter_alt</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <button mat-menu-item (click)="toggleLayer(-1, $event)">
        <mat-checkbox [checked]="checkAllLayers" (click)="toggleLayer(-1, $event)">Todos</mat-checkbox>
      </button>
      <ng-container *ngFor="let item of epigrafesList">
        <div style="display: flex; align-items: center;">
          <button mat-menu-item style="display: inline-flex; height: 40px !important;">
            <mat-checkbox [value]="item.id" [checked]="item.checked"
              (click)="toggleLayer(item.id, $event)">{{item.name}}</mat-checkbox>
          </button>
          <!-- <span class="squareColor" [ngStyle]="{'background-color': item.color}" (click)="openBottomSheet(item)"></span> -->
          <span class="squareColor" [ngStyle]="{'background-color': getColorById(item.id)}" (click)="openBottomSheet(item)"></span>
        </div>
      </ng-container>
    </mat-menu>

    <fieldset style="display: block;">
      <input id="feature-filter" type="text" placeholder="Buscar..." (input)="onSearch($event)">
    </fieldset>
    <div class="basic-container">
      <div *ngIf="accordion2.length > 0">
        <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport">
          <ng-container *ngFor="let item of accordion2">
                  {{item.nombre}}
              <ng-container *ngFor="let e of item.lista">
                <a href="javascript:void(0);" [ngClass]="{'itemlist': e.center, 'itemlist2': !e.center}"
                  (click)="fijarCentro(e)">{{e.label}}</a>
              </ng-container>
          </ng-container>

        </cdk-virtual-scroll-viewport>
      </div>
      <mat-accordion *ngIf="accordion2.length < 1">
        <cdk-virtual-scroll-viewport itemSize="50" class="example-viewport">
          <ng-container *ngFor="let item of accordion">
      
            <mat-expansion-panel>
              <!-- #enddocregion hide-toggle -->
              <mat-expansion-panel-header>
                <mat-panel-title>
                  {{item.nombre}}
                </mat-panel-title>
              </mat-expansion-panel-header>
              <ng-container *ngFor="let e of item.lista">
                <!-- <a href="javascript:void(0);" class="itemlist" (click)="fijarCentro(e)">{{e.label}}</a> -->
                <a href="javascript:void(0);" [ngClass]="{'itemlist': e.center, 'itemlist2': !e.center}"
                  (click)="fijarCentro(e)">{{e.label}}</a>
              </ng-container>
            </mat-expansion-panel>
          </ng-container>

        </cdk-virtual-scroll-viewport>
      </mat-accordion>
    </div>
    <!-- <div id="featureListing" #featureListing class="listing"></div> -->
  </div>
</ng-container>
