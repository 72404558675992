import { EventEmitter, Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';
import jwt_decode from 'jwt-decode';

@Injectable()
export class AuthService {
  private _authenticated: boolean = false;
  private urlApi = environment.apiUrl;
  private token = '';
  private _rol = '';
  private _idMunicipio = 0;
  ee: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  // -----------------------------------------------------------------------------------------------------
  // @ Accessors
  // -----------------------------------------------------------------------------------------------------

  /**
   * Setter & getter for access token
   */
  set accessToken(token: string) {
    localStorage.setItem('accessTokenMaps', token);
  }

  get accessToken(): string {
    const token = localStorage.getItem('accessTokenMaps') ?? '';
    this.fijarToken(token);
    return token;
  }

  set rol(rol: string) {
    this._rol = rol;
  }

  get rol(): string {
    return this._rol;
  }
  set idMunicipio(id: number) {
    this._idMunicipio = id;
  }

  get idMunicipio(): number {
    return this._idMunicipio;
  }

  /**
   * Setter & getter for authenticated flag
   */
  set authenticated(authenticated: boolean) {
    this._authenticated = authenticated;
  }

  get authenticated(): boolean {
    return this._authenticated;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   */
  signIn(credentials: { email: string; password: string }): Observable<any> {
    // Throw error, if the user is already logged in
    if (this._authenticated) {
      return throwError(() => new Error('User is already logged in.'));
    }

    return this._httpClient.post(`${this.urlApi}login`, credentials).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage
        this.accessToken = response.token;
        let a: any = this.decodeToken(this.accessToken);
        this._rol = a.rol;
        this._idMunicipio = a.municipioId || 0;
        // Set the authenticated flag to true
        this._authenticated = true;

        // Store the user on the user service
        // this._userService.user = response.user;

        // Return a new observable with the response
        return of(true);
      })
    );
  }

  /**
   * Sign out
   */
  signOut(): Observable<any> {
    // Remove the access token from the local storage
    localStorage.removeItem('accessToken');

    // Set the authenticated flag to false
    this._authenticated = false;
    console.log('ab ', this._authenticated);

    // Return the observable
    return of(true);
  }

  decodeToken(token: string): string {
    return jwt_decode(token);
  }

  fijarToken(token: string) {
    let a: any;
    try {
      a = this.decodeToken(token);
      this._rol = a.rol;
      this._idMunicipio = a.municipioId || 0;
      this.accessToken = token;
      this._authenticated = true;
      setTimeout(() => {
        this.ee.emit(true);
      }, 1000);
      return true;
    } catch (error) {
      return false;
    }
  }
}
