import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { FeatureService } from '../../../../services/feature.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../../auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-caminos',
  templateUrl: './caminos.component.html',
  styles: [],
})
export class CaminosComponent implements OnInit {
  @Input() itemData: any;
  updateForm: UntypedFormGroup = this._formBuilder.group({
    id: [null],
    n_orden: [],
    sign_archivo: ['', Validators.required],
    estado_mueble: [null],
    referencia_catastral: [null],
    naturaleza: [null],
    c_sup_catastral: [null],
    denominacion: [null],
    final: [null],
    origen: [null],
    tipoViaId: [null],
    longitud: [null],
    ancho_via: [null],
    caract_est_cons: [null],
    caract_t_pavi: [null],
    numero: [null],
    s_sup_longitud: [null],
    s_sup_registral: [null],
    s_superficie: [null],
    s_sup_ancho: [null],
  });
  rolPermitir = false;
  tiposVias: any[] = [];
  nombreTipoVia = '';
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private featureService: FeatureService,
    private _snackBar: MatSnackBar,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.updateForm.patchValue(this.itemData);
    if (this.authService.accessToken) {
      this.rolPermitir = environment.rolesPermitirEditar.includes(
        this.authService.rol
      );
    }
    this.featureService.getTiposVias().subscribe((resp) => {
      this.tiposVias = resp.result;
      var item = this.tiposVias.find((e)=>e.id == this.itemData.tipoViaId);
      if(item) {
        this.nombreTipoVia = item.nombre;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['itemData'].currentValue) {
      this.itemData = { ...this.itemData, ...changes['itemData'].currentValue };
      this.updateForm.patchValue(this.itemData);
    }
  }

  editar() {
    const newData = { ...this.itemData, ...this.updateForm.value };
    this.featureService.updateBien(this.itemData.id, newData).subscribe({
      next: (data) => {
        this.openSnackBar('Solicitud de actualización enviada');
      },
      error: (err) => {
        this.openSnackBar('Error');
      },
    });
  }

  openSnackBar(msg: string = '') {
    this._snackBar.open(msg, 'Cerrar', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  @Output() updatePoligono: EventEmitter<boolean> = new EventEmitter<boolean>();
  actualizarPoligono() {
    this.updatePoligono.emit(true);
  }
}
