<h2 mat-dialog-title>{{itemData.denominacion}}<span style="font-size: 12px; padding-left: 10px;"
    *ngIf="itemData.epigrafe">{{itemData.epigrafe.codigo}} -
    {{itemData.epigrafe.nombre}}</span></h2>
<form [formGroup]="updateForm">
  <mat-form-field appearance="fill">
    <mat-label>Num. Orden</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'n_orden'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Tipo de via</mat-label>
    <mat-select [formControlName]="'tipoViaId'" *ngIf="rolPermitir">
      <mat-option *ngFor="let item of tiposVias" [value]="item.id">
        {{item.nombre}}</mat-option>
    </mat-select>
    <input matInput placeholder="Simple placeholder" [value]="nombreTipoVia" [readonly]="!rolPermitir" *ngIf="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Denominación</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'denominacion'" [readonly]="!rolPermitir">
  </mat-form-field>


  <mat-form-field appearance="fill">
    <mat-label>Ref. Catastral</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'referencia_catastral'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Origen</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'origen'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Final</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'final'"[readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Longitud (m)</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'s_sup_longitud'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Ancho (m)</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'s_sup_ancho'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Superficie (m2)</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'s_superficie'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Tipo Pavimento</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'caract_t_pavi'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Estado Conservación</mat-label>
    <input matInput placeholder="Simple placeholder" [formControlName]="'caract_est_cons'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-dialog-actions align="end">
    <button mat-button (click)="actualizarPoligono()" *ngIf="rolPermitir">Actualizar Polígono</button>
    <button mat-button (click)="editar()" *ngIf="rolPermitir">Editar</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Cerrar</button>
  </mat-dialog-actions>

</form>
