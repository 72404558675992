import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { MapaComponent } from './pages/mapa/mapa.component';
import { NgxMapboxGLModule } from 'ngx-mapbox-gl';
import {
  CommonModule,
  DatePipe,
  HashLocationStrategy,
  LocationStrategy,
} from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ModalInfoComponent } from './pages/modal-info/modal-info.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ModalNuevoComponent } from './pages/modal-nuevo/modal-nuevo.component';
import { ColorPickerModule } from 'ngx-color-picker';
import { MaterialAppModule } from '../material.module';
import { AuthModule } from './auth/auth.module';
import { HomeComponent } from './pages/modal-info/pages/home/home.component';
import { NgxSkeletonLoaderModule } from 'ngx-skeleton-loader';
import { CallesComponent } from './pages/modal-info/pages/calles/calles.component';
import { CaminosComponent } from './pages/modal-info/pages/caminos/caminos.component';
import { InmueblesUrbanosComponent } from './pages/modal-info/pages/inmuebles-urbanos/inmuebles-urbanos.component';
import { InmueblesRusticosComponent } from './pages/modal-info/pages/inmuebles-rusticos/inmuebles-rusticos.component';
import { ConfigbottomSheetComponent } from './pages/mapa/configbottom-sheet/configbottom-sheet.component';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { PipesModule } from './pipes/pipes.module';
import { PruebaComponent } from './pages/prueba/prueba.component';

@NgModule({
  declarations: [
    AppComponent,
    MapaComponent,
    ModalInfoComponent,
    ModalNuevoComponent,
    HomeComponent,
    CallesComponent,
    CaminosComponent,
    InmueblesUrbanosComponent,
    InmueblesRusticosComponent,
    ConfigbottomSheetComponent,
    PruebaComponent,
  ],
  imports: [
    CommonModule,
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ColorPickerModule,
    MaterialAppModule,
    NgxMapboxGLModule.withConfig({
      accessToken:
        'pk.eyJ1IjoianN2ZXJhIiwiYSI6ImNsZGR5NGY4YzAzbnAzcW1wc2p1ZHBmNjMifQ.4fmQSkuuKFeW1HoIYB0LmQ',
    }),
    AuthModule,
    NgxSkeletonLoaderModule,
    MatFormFieldModule,
    MatInputModule,
    PipesModule,
  ],
  providers: [DatePipe,{ provide: LocationStrategy, useClass: HashLocationStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
