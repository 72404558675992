import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import { AuthService } from '../../auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styles: [],
})
export class LoginComponent implements OnInit {
  loginSuccess = false;
  loginError = false;
  token = '';
  msg = 'login works!';
  constructor(
    private router: Router,
    private authService: AuthService,
    private activatedRoute: ActivatedRoute
  ) {
    this.token = this.activatedRoute.snapshot.paramMap.get('token') ?? '';
  }

  ngOnInit(): void {
    const resp = this.authService.fijarToken(this.token);
    this.loginSuccess = resp;
    if (!resp) {
      this.msg = 'Invalid authentication!';
      return;
    }
    // this.router.navigateByUrl('maps');

    // this.authService
    //   .signIn({ email: 'test@mail.com', password: '123' })
    //   .subscribe({
    //     next: (data) => {
    //       this.loginSuccess = true;
    //     },
    //     error: (err) => {
    //       this.loginSuccess = false;
    //     },
    //     complete: () => {
    //       if (this.loginSuccess) {
    //         this.loginError = false;
    //         this.router.navigateByUrl('maps');
    //       } else {
    //         this.loginError = true;
    //       }
    //     },
    //   });
  }
}
