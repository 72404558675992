import {
  Component,
  OnInit,
  Input,
  Output,
  EventEmitter,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { FeatureService } from '../../../../services/feature.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../../auth/auth.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inmuebles-urbanos',
  templateUrl: './inmuebles-urbanos.component.html',
  styles: [],
})
export class InmueblesUrbanosComponent implements OnInit {
  @Input() itemData: any;
  @Input() itemDataBase: any;
  @Output() updatePoligono: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() successUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  fecha_constr: any;
  updateForm: UntypedFormGroup = this._formBuilder.group({
    id: [null],
    n_orden: [],
    sign_archivo: ['', Validators.required],
    estado_mueble: [null],
    referencia_catastral: [null],
    naturaleza: [null],
    c_sup_catastral: [0],
    denominacion: [null],
    finca: [null],
    titulo_propiedad: [null],
    tipoViaId: [null],
    longitud: [null],
    ancho_via: [null],
    caract_est_cons: [null],
    fecha_construccion: [null],
    via_publica: [null],
    via_publica_al_frente: [null],
    numero: [null],
    s_sup_registral: [0],
    s_sup_longitud: [0],
    s_sup_catastral: [0]

  });
  rolPermitir = false;
  tiposVias: any[] = [];
  nombreTipoVia = '';
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private featureService: FeatureService,
    private _snackBar: MatSnackBar,
    private authService: AuthService,
    private datePipe: DatePipe
  ) {
  }

  ngOnInit(): void {


    if (this.itemData.fecha_construccion) {
      this.itemData.fecha_construccion = this.datePipe.transform(this.itemData.fecha_construccion, 'YYYY');
      console.log("AÑOOOOO");
      console.log(this.itemData.fecha_construccion);
    } else {
      this.itemData.fecha_construccion = null;
    }

    this.updateForm.patchValue(this.itemData);
    if (this.authService.accessToken) {
      this.rolPermitir = environment.rolesPermitirEditar.includes(
        this.authService.rol
      );
    }
    this.featureService.getTiposVias().subscribe((resp) => {
      this.tiposVias = resp.result;
      var item = this.tiposVias.find((e) => e.id == this.itemData.tipoViaId);
      if (item) {
        this.nombreTipoVia = item.nombre;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['itemData'].currentValue) {
      this.itemData = { ...this.itemData, ...changes['itemData'].currentValue };
      console.log("changuess");
      console.log({ ...changes['itemData'].currentValue });

      // this.updateForm.patchValue(this.itemData);
    }
  }



  editar() {

    if (this.updateForm.invalid) {
      return;
    }

    const newData = { ...this.itemData, ...this.updateForm.value };
    newData.estadoPeticionId = null; //Pendiente
    newData.tipoPeticionId = 3; // 3 Modificacion
    newData.tipoPeticion = null; //Modificacion
    newData.estadoPeticion = null;
    newData.observacion_modificacion = this.featureService.observacion_modificacion;

    //Convertimos el año del formulario en una fecha validad 
    if (newData.fecha_construccion) {
      newData.fecha_construccion = new Date("01-01-" + this.updateForm.controls['fecha_construccion'].value);
    } else {
      newData.fecha_construccion = null;
    }
    

    this.featureService.update_create_solicitud(this.itemData.id, newData).subscribe({
      next: (data) => {
        this.openSnackBar('Solicitud de modificación generada con éxito');
        this.successUpdate.emit(true);
      },
      error: (err) => {
        this.openSnackBar('Error');
      },
    });
  }


  getUrlEpigrafeByCode(code: string) {
    let url = "";
    switch (code) {
      case "1.1.1.":
        url = "inmuebles-urbanos";
        break;
      case "1.1.2.":
        url = "parques-jardines";
        break;
      case "1.1.3.":
        url = "solares";
        break;
      case "1.1.4.":
        url = "infraestructuras";
        break;
      default:
        break;
    }
    return url;
  }

  openSnackBar(msg: string = '') {
    this._snackBar.open(msg, 'Cerrar', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  actualizarPoligono() {
    this.updatePoligono.emit(true);
    this.featureService.observacion_modificacion = "Actualización de los límites (Polígono) del bien";
  }
}
