<h2 mat-dialog-title>{{itemData.nombre}}<span style="font-size: 12px; padding-left: 10px;"
    *ngIf="itemData.epigrafe">{{itemData.epigrafe.codigo}} -
    {{itemData.epigrafe.nombre}}</span></h2>
<form [formGroup]="updateForm">
  <mat-form-field appearance="fill">
    <mat-label>Num. Orden</mat-label>
    <input matInput placeholder="Orden" [formControlName]="'n_orden'" [readonly]="!rolPermitir">
  </mat-form-field>
  <!--
  <mat-form-field appearance="fill">
    <mat-label>Denominación</mat-label>
    <input matInput placeholder="Denominación" [formControlName]="'denominacion'">
  </mat-form-field>
-->
  <mat-form-field appearance="fill">
    <mat-label>Tipo de via</mat-label>
    <mat-select [formControlName]="'tipoViaId'" *ngIf="rolPermitir">
      <mat-option *ngFor="let item of tiposVias" [value]="item.id">
        {{item.nombre}}</mat-option>
    </mat-select>
    <input matInput placeholder="NO CONSTA" [value]="nombreTipoVia" [readonly]="!rolPermitir" *ngIf="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Vía frente</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'via_publica_al_frente' || 'Sin Regisro'" [readonly]="!rolPermitir">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Num</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'numero'" [readonly]="!rolPermitir">
  </mat-form-field>



  <mat-form-field appearance="fill">
    <mat-label>Ref. Catastral</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'referencia_catastral'" [readonly]="!rolPermitir">
  </mat-form-field>
  <mat-form-field appearance="fill">
    <mat-label>Naturaleza Dominio</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'naturaleza'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Título Propiedad</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'titulo_propiedad'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Finca Registral</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'finca'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Suelo. Sup. Catastral (m2)</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'s_sup_catastral'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="itemData.epigrafeId!=20">
    <mat-label>Const. Sup. Registral (m2)</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'s_sup_registral'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-form-field appearance="fill" *ngIf="itemData.epigrafeId!=20">
    <mat-label>Const. Sup. Catastral (m2)</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'c_sup_catastral'" [readonly]="!rolPermitir">
  </mat-form-field>

  <!--
  <mat-form-field appearance="fill">
    <mat-label>Sup. Suelo (m2)</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'s_sup_registral'">
  </mat-form-field>

  <mat-form-field appearance="fill">
    <mat-label>Sup. Construida (m2)</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'c_sup_catastral'">
  </mat-form-field>


  <mat-form-field appearance="fill">
    <mat-label>Superficie (m2)</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'s_sup_longitud'">
  </mat-form-field>
    -->
  <mat-form-field appearance="fill" *ngIf="itemData.epigrafeId!=20">
    <mat-label>Año Construcción</mat-label>
    <input matInput placeholder="Sin Registro" [formControlName]="'fecha_construccion'" [readonly]="!rolPermitir">
  </mat-form-field>

  <mat-dialog-actions align="end">
    <button mat-button (click)="actualizarPoligono()" *ngIf="rolPermitir">Actualizar Polígono</button>
    <button mat-button (click)="editar()" *ngIf="rolPermitir">Editar</button>
    <button mat-button [mat-dialog-close]="true" cdkFocusInitial>Cerrar</button>
  </mat-dialog-actions>

</form>
