import {
  Component,
  OnInit,
  Input,
  EventEmitter,
  Output,
  SimpleChanges,
} from '@angular/core';
import {
  UntypedFormGroup,
  Validators,
  UntypedFormBuilder,
} from '@angular/forms';
import { FeatureService } from '../../../../services/feature.service';
import { MatSnackBar } from '@angular/material/snack-bar';
import { AuthService } from '../../../../auth/auth.service';
import { DatePipe } from '@angular/common';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-inmuebles-rusticos',
  templateUrl: './inmuebles-rusticos.component.html',
  styles: [],
})
export class InmueblesRusticosComponent implements OnInit {
  @Input() itemData: any;
  @Output() updatePoligono: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() successUpdate: EventEmitter<boolean> = new EventEmitter<boolean>();

  updateForm: UntypedFormGroup = this._formBuilder.group({
    id: [null],
    n_orden: [],
    sign_archivo: ['', Validators.required],
    estado_mueble: [null],
    referencia_catastral: [null],
    naturaleza: [null],
    c_sup_catastral: [null],
    denominacion: [null],
    final: [null],
    origen: [null],
    tipoViaId: [null],
    longitud: [null],
    ancho_via: [null],
    caract_est_cons: [null],
    caract_t_pavi: [null],
    aprovechamiento: [null],
    s_sup_longitud: [null],
    s_sup_registral: [null],
    titulo_propiedad: [null],
    s_valor_adquisicion: [null],
    fecha_adquisicion: [null],
    s_fecha_adquisicion: [null],
    s_sup_catastral: [null],
    parcela: [null],
    poligono: [null],
    finca: [null],
    via_publica: [null],
    numero: [null],
    paraje: [null],
  });
  rolPermitir = false;
  nombreEstadoMueble = '';
  nombreTipoVia = '';
  tiposVias: any[] = [];
  constructor(
    private _formBuilder: UntypedFormBuilder,
    private featureService: FeatureService,
    private _snackBar: MatSnackBar,
    private authService: AuthService
  ) {}

  ngOnInit(): void {
    this.updateForm.patchValue(this.itemData);
    this.nombreEstadoMueble = this.itemData ? this.itemData.estado_mueble : '';
    if (this.authService.accessToken) {
      this.rolPermitir = environment.rolesPermitirEditar.includes(
        this.authService.rol
      );
    }
    this.featureService.getTiposVias().subscribe((resp) => {
      this.tiposVias = resp.result;
      var item = this.tiposVias.find((e)=>e.id == this.itemData.tipoViaId);
      if(item) {
        this.nombreTipoVia = item.nombre;
      }
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['itemData'].currentValue) {
      this.itemData = { ...this.itemData, ...changes['itemData'].currentValue };
      if (this.itemData.estado_mueble) {
        this.itemData.estado_mueble = this.itemData.estado_mueble
          .toString()
          .toUpperCase();
      }

    
      //this.updateForm.patchValue(this.itemData);
    }
  }

  editar() {
    const newData = { ...this.itemData, ...this.updateForm.value };
    newData.estadoPeticionId=null; //Modificacion
    newData.tipoPeticionId=3; //Modificacion
    newData.tipoPeticion=null; //Modificacion
    newData.estadoPeticion=null;

    newData.observacion_modificacion=this.featureService.observacion_modificacion;
    this.featureService.update_create_solicitud(this.itemData.id, newData).subscribe({
      next: (data) => {
        this.openSnackBar('Solicitud de modificación generada con éxito');
        this.successUpdate.emit(true);
      },
      error: (err) => {
        this.openSnackBar('Error');
        console.log("ERROR");
        console.log(err);
      },
    });
  }

  openSnackBar(msg: string = '') {
    this._snackBar.open(msg, 'Cerrar', {
      horizontalPosition: 'center',
      verticalPosition: 'top',
    });
  }

  actualizarPoligono() {
    this.updatePoligono.emit(true);
    this.featureService.observacion_modificacion="Actualización de los límites (Polígono) del bien";
  }
}
