import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  HostListener,
  NgZone,
  OnInit,
  Renderer2,
  ViewChild,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { LngLatLike, Map, MapMouseEvent } from 'mapbox-gl';
import { ModalInfoComponent } from '../modal-info/modal-info.component';
import { MatDialog } from '@angular/material/dialog';
import * as turf from '@turf/turf';
import * as MapboxDraw from '@mapbox/mapbox-gl-draw';
import { ModalNuevoComponent } from '../modal-nuevo/modal-nuevo.component';
import { environment } from '../../../environments/environment';
import { AuthService } from '../../auth/auth.service';
import { Router, ActivatedRoute } from '@angular/router';
import { ConfigbottomSheetComponent } from './configbottom-sheet/configbottom-sheet.component';
import {
  MatBottomSheet,
  MatBottomSheetRef,
} from '@angular/material/bottom-sheet';
import { FeatureService } from 'src/app/services/feature.service';
import { Subject, debounceTime, distinctUntilChanged } from 'rxjs';

enum TipoGeo {
  POLILINNEA = 'Polygon',
  MULTILINE = 'MultiLineString',
  MULTIPOLYGON = 'MultiPolygon',
}
@Component({
  selector: 'app-mapa',
  templateUrl: './mapa.component.html',
  styleUrls: ['./mapa.component.scss'],
})
export class MapaComponent implements OnInit {
  urlApi = environment.apiUrl;
  map?: Map;
  cursorStyle?: string;
  center: LngLatLike = [-3.713, 40.2085];
  accordion: any[] = [];
  accordion2: any[] = [];
  loadLatLng = true;

  geometries: any[] = [];
  geometriesIcons: any[] = [];
  geometries2: any[] = [];
  geometriesIcons2: any[] = [];

  // layerId = 'basic';
  layerId = 'satellite-streets';
  layerId2 = null;
  style: string = '';
  layouts: any = {
    contours: {
      visibility: 'visible',
    },
    museums: {
      visibility: 'visible',
    },
    8: {
      visibility: 'visible',
    },
    20: {
      visibility: 'visible',
    },
    pu: {
      visibility: 'visible',
    },
    19: {
      visibility: 'visible',
    },
    psu: {
      visibility: 'visible',
    },
    li: {
      visibility: 'visible',
    },
    4: {
      visibility: 'visible',
    },
    9: {
      visibility: 'visible',
    },
    10: {
      visibility: 'visible',
    },
    11: {
      visibility: 'visible',
    },
    17: {
      visibility: 'visible',
    },
    18: {
      visibility: 'visible',
    },
  };
  addText = 'Agregar';
  addFlag = false;
  addStyle = 'primary';
  coloresEpigrades: any = {};
  epigrafesList: any[] = [
    {
      url: `datos-bienes?epigrafeId=8&municipioId=IDMUNICIPIO&limit=1000`,
      id: '8',
      idName: 'id',
      color: '#b4a7d6',
      checked: true,
      name: '1.1.1. Bienes Inmuebles',
      openModal: true,
      type: TipoGeo.POLILINNEA,
      filter: true,
      api: true,
    },
    {
      url: `datos-bienes?epigrafeId=9&municipioId=IDMUNICIPIO&limit=1000`,
      id: '9',
      idName: 'id',
      color: '#8fce00',
      checked: true,
      name: '1.1.2. Parques y Jardines',
      openModal: true,
      type: TipoGeo.POLILINNEA,
      filter: true,
      api: true,
    },
    {
      url: `datos-bienes?epigrafeId=10&municipioId=IDMUNICIPIO&limit=1000`,
      id: '10',
      idName: 'id',
      color: '#cd4588',
      checked: true,
      name: '1.1.3. Solares',
      openModal: true,
      type: TipoGeo.POLILINNEA,
      filter: true,
      api: true,
    },
    {
      url: `datos-bienes?epigrafeId=11&municipioId=IDMUNICIPIO&limit=1000`,
      id: '11',
      idName: 'id',
      color: '#797891',
      checked: true,
      name: '1.1.4. Infraestructuras',
      openModal: true,
      type: TipoGeo.POLILINNEA,
      filter: true,
      api: true,
    },
    {
      url: `datos-bienes?epigrafeId=17&municipioId=IDMUNICIPIO&limit=1000`,
      id: '17',
      idName: 'id',
      color: '#FE6F5E',
      checked: true,
      name: '1.2. Inmuebles Rústicos',
      openModal: true,
      type: TipoGeo.POLILINNEA,
      filter: true,
      api: true,
    },
    {
      url: `datos-bienes?epigrafeId=18&municipioId=IDMUNICIPIO&limit=1000`,
      id: '18',
      idName: 'id',
      color: '#351c75',
      checked: true,
      name: '1.3. Vías Públicas Urbanas',
      openModal: true,
      type: TipoGeo.MULTILINE, //Estaba Multiline
      filter: true,
      api: true,
    },
    {
      url: `datos-bienes?epigrafeId=19&municipioId=IDMUNICIPIO&limit=1000`,
      id: '19',
      idName: 'id',
      color: '#744700',
      checked: true,
      name: '1.4. Vías Públicas Rústicas',
      openModal: true,
      type: TipoGeo.MULTIPOLYGON,
      // type: TipoGeo.POLILINNEA,
      filter: true,
      api: true,
    },
    {
      url: `datos-bienes?epigrafeId=4&municipioId=IDMUNICIPIO&limit=1000`,
      id: '4',
      idName: 'id',
      color: '#ebc860',
      checked: true,
      name: '8. Bienes Revertibles',
      openModal: true,
      type: TipoGeo.POLILINNEA,
      filter: true,
      api: true,
    },
    {
      url: `datos-bienes?epigrafeId=20&municipioId=IDMUNICIPIO&limit=1000`,
      id: '20',
      idName: 'id',
      color: '#32A0A8',
      checked: true,
      name: 'Patrimonio Suelo',
      openModal: true,
      type: TipoGeo.POLILINNEA,
      filter: true,
      api: true,
    },
    {
      url: 'limites.json',
      id: 'li',
      idName: 'id',
      color: '#B02C3A',
      checked: true,
      name: 'Límites',
      openModal: false,
      type: TipoGeo.MULTILINE,
    },
  ];

  filterEl = document.getElementById('feature-filter');

  rolPermitir = false;
  rolPermitirAgregar = false;
  mostrarMapa = false;
  actualizarPoligono = false;
  idMunicipio = 0;

  constructor(
    private httpClient: HttpClient,
    private ChangeDetectorRef: ChangeDetectorRef,
    public dialog: MatDialog,
    public dialog2: MatDialog,
    private zone: NgZone,
    private authService: AuthService,
    private router: Router,
    private _bottomSheet: MatBottomSheet,
    private featureService: FeatureService,
    private activatedRoute: ActivatedRoute
  ) {
    this.getColores();
    if (!this.authService.accessToken) {
      const token = this.activatedRoute.snapshot.paramMap.get('token') ?? '';
      const resp = this.authService.fijarToken(token);
    } else {
      this.rolPermitir = environment.rolesPermitirEditar.includes(
        this.authService.rol
      );
      this.rolPermitirAgregar = environment.rolesPermitirAgregar.includes(
        this.authService.rol
      );
      this.idMunicipio = this.authService.idMunicipio;
      this.epigrafesList.map((e) => {
    
        e.url = e.url.toString().replace('IDMUNICIPIO', this.idMunicipio);
      });
    }
  }

  ngOnInit(): void {
    this.style = `mapbox://styles/mapbox/${this.layerId}-v9`;
    this.authService.ee.subscribe((counter) => {
      this.mostrarMapa = counter;
    });
  }

  hoveredStateId = null;

  // Este metodo abre el modal de informacion de los bienes
  centerMapTo(evt: MapMouseEvent) {
    if(!this.actualizarPoligono){
      this.zone.run(() => this.openDialog((<any>evt).features[0].properties));
    }
  }

  auxd = 0;
  auxStyle = 1;

  openDialog(itemData: any) {
    if (this.auxd > 1 && this.auxStyle > 1) {
      this.auxd--;
      // if(this.auxd == 0){
      //   this.auxd = 0;
      // }
      return;
    }
    this.auxd = this.auxStyle;
    const dialogRef = this.dialog2.open(ModalInfoComponent, { data: itemData });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        if (result.event == 'polygono') {
          if (result.data.status === true) {
            this.updateItemData = result.data.itemData;
            this.dibujarActualizarPoligono();
          }
        } else if (result.event == 'successEditar') {
          if (result.data.status === true) {
            const newData = result.data.itemData;
            this.actualizarSourceMap(newData);
          }
        }
      }
    });
  }
  updateItemData: any = null;

  changeStyle(layerId: any) {
    let estilo = `mapbox://styles/mapbox/${layerId}-v9`;
    if (layerId == 'satellite-streets') {
      estilo = `mapbox://styles/mapbox/satellite-streets-v12`;
    }
    this.map!.setStyle(estilo);
    for (let index = 0; index < this.epigrafesList.length; index++) {
      const e = this.epigrafesList[index];
      if (this.map!.getLayer(`layer-${e.id}`)) {
        this.map!.removeLayer(`layer-${e.id}`);
      }
      if (this.map!.getSource(`source-${e.id}`)) {
        this.map!.removeSource(`source-${e.id}`);
      }
    }
    if (this.map!.getStyle()) {
      this.loadData();
    }
    this.auxStyle++;
  }

  checkAllLayers = true;
  toggleLayer(evt: any, event: any) {
    event.preventDefault();
    if (evt == -1) {
      this.checkAllLayers = !this.checkAllLayers;
      for (let i = 0; i < this.epigrafesList.length; i++) {
        const e = this.epigrafesList[i];
        e.checked = this.checkAllLayers;
        const v = e.checked ? 'visible' : 'none';
        this.map!.setLayoutProperty(`layer-${e.id}`, 'visibility', v);
      }
      event.stopPropagation();
      return;
    }

    const visibility = this.map!.getLayoutProperty(
      `layer-${evt}`,
      'visibility'
    );
    this.layouts[evt] = {
      ...this.layouts[evt],
      visibility: visibility === 'visible' ? 'none' : 'visible',
    };
    let ilayer = this.epigrafesList.findIndex((e: any) => e.id == evt);
    if (ilayer != -1) {
      this.epigrafesList[ilayer].checked = !this.epigrafesList[ilayer].checked;
      console.log(this.epigrafesList[ilayer]);
    }
    let index = -1;
    for (let i = 0; i < this.epigrafesList.length; i++) {
      const e = this.epigrafesList[i];
      if (!e.checked) {
        index = 1;
        break;
      }
    }
    this.checkAllLayers = index === 1 ? false : true;
    if (visibility === 'visible') {
      this.map!.setLayoutProperty(`layer-${evt}`, 'visibility', 'none');
      // this.className = '';
    } else {
      // this.className = 'active';
      this.map!.setLayoutProperty(`layer-${evt}`, 'visibility', 'visible');
    }
    event.stopPropagation();
  }

  hoverFilter = ['==', 'name', ''];
  hoverFilter2 = ['==', 'name', ''];
  selectedPoint: any = null;
  activateHoverOn(evt: any) {
    this.hoverFilter = ['==', 'name', evt.features[0].properties.name];
    //
    this.selectedPoint = null;
    this.ChangeDetectorRef.detectChanges();
  }

  activateHoverOn2(evt: any) {
    this.hoverFilter2 = ['==', 'name', evt.features[0].properties.name];
    this.selectedPoint = null;
    this.ChangeDetectorRef.detectChanges();
  }

  disableHover() {
    this.hoverFilter = ['==', 'name', ''];
  }
  disableHover2() {
    this.hoverFilter2 = ['==', 'name', ''];
  }

  draw = new MapboxDraw({
    displayControlsDefault: false,
    // Select which mapbox-gl-draw control buttons to add to the map.
    controls: {
      polygon: true,
      trash: true,
    },
    // Set mapbox-gl-draw to draw by default.
    // The user does not have to click the polygon control button first.
    defaultMode: 'draw_polygon',
  });
  agregar() {
    this.addFlag = !this.addFlag;
    this.addText = !this.addFlag ? 'Agregar' : 'Cancelar';
    this.addStyle = !this.addFlag ? 'primary' : 'accent';
    if (!this.addFlag) {
      this.map!.removeControl(this.draw);
      return;
    }

    this.map!.addControl(this.draw);
  }
  
  private searchSubject = new Subject<string>();
  eventoBuscador(): void{
    this.searchSubject
      .pipe(debounceTime(300), distinctUntilChanged())
      .subscribe((searchTerm) => {
        const filtered: any[] = [];
        for (const feature of this.dataMap) {
          let name = feature.properties.label;
          name = name.toLowerCase();
          if (name.includes(searchTerm)) {
            filtered.push(feature);
          }
        }
        this.renderListingsFilter(filtered, [], 'Resultados');
      });
  }

  onSearch(searchTerm: any) {
    this.accordion2 = [{ nombre: 'nombre', lista: [] }];
    const val = searchTerm.target.value ?? '';
    if (val.trim() == '') {
      this.renderListingsFilter([], [], 'Resultados');
      return;
    }
    this.searchSubject.next(val);
  }

  ngAfterViewInit2(): void {
    this.filterEl = document.getElementById('feature-filter');
    this.filterEl!.addEventListener('keyup', (e: any) => {
      // const value = normalize(e.target.value);
      let value = e!.target!.value;
      value = value.toLowerCase();
      // Filter visible features that match the input value.
      const filtered: any[] = [];
      for (const feature of this.dataMap) {
        let name = feature.properties.label;
        name = name.toLowerCase();
        if (name.includes(value)) {
          filtered.push(feature);
        }
      }

      this.renderListings(filtered, [], 'Resultados');
    });
  }

  onLoadMap(event: any) {
    if (!this.authService.idMunicipio) {
      const token = this.activatedRoute.snapshot.paramMap.get('token') ?? '';
      const resp = this.authService.fijarToken(token);
    }
    this.idMunicipio = this.authService.idMunicipio;
    this.epigrafesList.map((e) => {
      e.url = e.url.toString().replace('IDMUNICIPIO', this.idMunicipio);
    });
    this.map = event;
    // this.map!.setStyle(`mapbox://styles/mapbox/${this.layerId}-v9`);
    this.map!.on('draw.create', (evt) => this.finDibujar(evt));
    this.map!.on('draw.update', (evt) => this.finDibujar(evt));
    this.map!.on('draw.delete', (evt) => this.finDibujar(evt));
    // this.map!.on('draw.create', (evt) => this.openDialogNNuevo(evt));
    // this.map!.on(
    //   'style.load',
    //   () => this.loadData()
    //   // addLayer();
    // );
    this.loadData();
    this.featureService.getInfoProvincia(this.idMunicipio).subscribe();
    this.eventoBuscador();
  }

  finDibujar(evt: any) {
    const data = this.draw.getAll();
    let geometry = null;
    if (data.features.length > 0) {
      const item = data.features[0];
      geometry = item.geometry;
    }
    if (!this.actualizarPoligono) {
      this.openDialogNNuevo(geometry);
    } else {
      // const data = this.draw.getAll();
      this.updateItemData.geom = geometry;
      // if (data.features.length > 0) {
      //   const item = data.features[0];
      //   this.updateItemData.geom = item.geometry;
      // } else {
      //   this.updateItemData.geom = null;
      // }
    }
  }

  openDialogNNuevo(data: any) {
    this.zone.run(() => this.dialogNuevo(data));
  }

  dialogNuevo(data: any) {
    const dialogRef = this.dialog.open(ModalNuevoComponent, {
      data: data,
    });

    dialogRef.afterClosed().subscribe((result: any) => {
      console.log(`Dialog result: ${result}`);
      if (result) {
        if (result.event == 'successNuevo') {
          if (result.data.status === true) {
            const newData = result.data.itemData;
            console.log(newData);
            this.agregar();
            this.actualizarSourceMap(newData);
          }
        }
      }
    });
  }

  async loadData() {
    // this.epigrafesList.forEach(async (e) => {
    this.accordion = [];
    for (let i = 0; i < this.epigrafesList.length; i++) {
      const e = this.epigrafesList[i];

      await this.loadDataEpigrafe(e);
    }
    // });
  }

  loadDataEpigrafe(item: any) {

    const dataCoord: any = [];
    const dataSinCoord: any[] = [];
    let url = `assets/${item.url}`;
    if (item.api) {
      url = `${item.url}`;
    }
    return new Promise((resolve, reject) => {
      this.httpClient.get(url).subscribe((data: any) => {
        if (item.api) {
          data = data.result ? data.result.datosBienes : [];
        }
        data = data.sort(function (a: any, b: any) {
          if (a.n_orden > b.n_orden) {
            return 1;
          } else if (a.n_orden < b.n_orden) {
            return -1;
          } else return 0;
        });

        data.forEach((element: any) => {
          if (item.api) {
            try {
              element.geom = JSON.parse(element.geom);
            } catch (error) {
              console.log('error geom ', element.id);
              element.geom = null;
            }
          }
          let label = '';
          if (!element.geom) {
            label = `Nro: ${element.n_orden} - ${element.nombre || ''}`;
            if(item.id == 18 || item.id == 19 ){
              label = `Nro: ${element.n_orden} - ${element.denominacion || ''}`;
            } else if (item.id == 4 ){
              label = `Nro: ${element.n_orden} - ${element.descripcion || ''}`;
            }
            dataSinCoord.push({
              id: element[item.idName],
              properties: {
                label,
                name: element[item.idName].toString(),
                ...element,
              },
            });
            return;
          } else {
            let listaLtLng: any[] = element.geom.coordinates[0][0];
            if (item.api) {
              label = `Nro: ${element.n_orden} - ${element.nombre || ''}`;
              if(item.id == 18 || item.id == 19 ){
                label = `Nro: ${element.n_orden} - ${element.denominacion || ''}`;
              } else if (item.id == 4 ){
                label = `Nro: ${element.n_orden} - ${element.descripcion || ''}`;
              }
            } else {
              label = `Nro: ${element.N_ORDEN} - ${element.NOMBRE}`;
            }
            var center = undefined;
            let coordAdd = [listaLtLng];
            if (element.geom && element.geom.type == TipoGeo.POLILINNEA) {
              listaLtLng = element.geom.coordinates;
              coordAdd = listaLtLng;
              let poly2 = turf.polygon(listaLtLng);
              center = turf.center(poly2);
            } else if (element.geom && item.type == TipoGeo.MULTILINE){
              listaLtLng = element.geom.coordinates;
              let poly2 = turf.multiLineString(listaLtLng);
              center = turf.center(poly2);
            } else if (item.type == TipoGeo.POLILINNEA) {
              let poly2 = turf.polygon([listaLtLng]);
              center = turf.center(poly2);
            }else if(item.type == TipoGeo.MULTIPOLYGON) {
              const coordinates = turf.getCoords(element.geom);
              const startPoint = turf.point(coordinates[0][0][0]);
              const endPoint = turf.point(coordinates[0][0][0]);
              center = turf.midpoint(startPoint, endPoint);
            }
            if (this.loadLatLng) {
              if (center && item.api) {
                this.loadLatLng = false;
                const c: any = center.geometry.coordinates || [0, 0];
                this.map!.flyTo({
                  zoom: 12,
                  center: c,
                  essential: true, // this animation is considered essential with respect to prefers-reduced-motion
                });
              }
            }
            let tipoGeometry = TipoGeo.POLILINNEA;

            let geomAdd = {
              // type: 'Polygon',
              type: tipoGeometry,
              coordinates: coordAdd,
            };
            if (item.type == TipoGeo.MULTILINE) {
              tipoGeometry = TipoGeo.MULTILINE;
              listaLtLng = element.geom.coordinates[0];
              coordAdd = listaLtLng;
              geomAdd = element.geom;
            }
            dataCoord.push({
              id: element[item.idName],
              customCenter: center,
              properties: {
                label,
                name: element[item.idName].toString(),
                ...element,
              },
              // geometry: {
              //   // type: 'Polygon',
              //   type: tipoGeometry,
              //   coordinates: coordAdd,
              // },
              geometry: geomAdd,
            });
          }
        });

        this.map!.addSource(`source-${item.id}`, {
          type: 'geojson',
          data: {
            type: 'FeatureCollection',
            features: dataCoord,
          },
        });
        if (item.type == TipoGeo.POLILINNEA || item.type == TipoGeo.MULTIPOLYGON) {
          this.map!.addLayer({
            id: `layer-${item.id}`,
            type: 'fill',
            source: `source-${item.id}`,
            layout: this.layouts[item.id],
            paint: {
              // 'fill-color': item.color,
              'fill-color': this.coloresEpigrades[`${item.id}`],
              'fill-opacity': [
                'case',
                ['boolean', ['feature-state', 'hover'], false],
                1,
                0.3,
              ],
            },
          });

          this.map!.on('mousemove', `layer-${item.id}`, (e: any) => {
            if (e.features.length > 0) {
              if (this.hoveredStateId !== null) {
                this.map!.setFeatureState(
                  { source: `source-${item.id}`, id: this.hoveredStateId },
                  { hover: false }
                );
              }
              this.hoveredStateId = e.features[0].id;
              this.map!.setFeatureState(
                { source: `source-${item.id}`, id: this.hoveredStateId || '' },
                { hover: true }
              );
            }
          });
          this.map!.on('mouseleave', `layer-${item.id}`, () => {
            if (this.hoveredStateId !== null) {
              this.map!.setFeatureState(
                { source: `source-${item.id}`, id: this.hoveredStateId },
                { hover: false }
              );
            }
            this.hoveredStateId = null;
          });
        } else {
          this.map!.addLayer({
            id: `layer-${item.id}`,
            type: 'line',
            source: `source-${item.id}`,
            layout: this.layouts[item.id],
            paint: {
              'line-width': 5,
              // 'line-color': item.color,
              'line-color': this.coloresEpigrades[`${item.id}`],
              // 'fill-color': item.color,
              // 'fill-opacity': [
              //   'case',
              //   ['boolean', ['feature-state', 'hover'], false],
              //   1,
              //   0.1,
              // ],
            },
          });
        }

        if (item.openModal) {
          this.map!.on('click', `layer-${item.id}`, (e) => {
            this.centerMapTo(e);
          });
        }
        if (item.filter) {
          this.renderListings(dataCoord, dataSinCoord, item.name);
        }
        resolve(true);
      });
    });
  }

  // listingEl = document.getElementById('featureListing');
  htmlContent: any[] = [];
  dataMap: any[] = [];
  @ViewChild('listingEl') div: ElementRef | undefined;
  renderListings(features: any[], dataSinCoord: any[], nombre: string) {
    // this.listingEl = document.getElementById('featureListing');
    this.htmlContent = ['asdas'];
    const empty = document.createElement('p');
    const epigrafe = document.createElement('h2');
    epigrafe.textContent = nombre;
    // this.listingEl!.appendChild(epigrafe);
    // Clear any existing listings
    // this.listingEl!.inne  rHTML = '';
    if (features.length || dataSinCoord.length) {
      const itemsData = [];
      for (const newItem of dataSinCoord) {
        const itemLink = document.createElement('a');
        itemLink.href = 'javascript:void(0);';
        itemLink.textContent = newItem.properties.label || '';
        itemLink.style.cssText = 'color: #000000; !important';
        this.dataMap.push(newItem);
        itemsData.push({
          label: itemLink.textContent,
          center: null,
          id: newItem.id,
          sign_archivo: newItem.properties.sign_archivo,
          epigrafeId: newItem.properties.epigrafeId,
          n_orden: newItem.properties.n_orden,
        });
      }
      for (const feature of features) {
        const itemLink = document.createElement('a');
        const label = `${feature.properties.name} (${feature.properties.abbrev})`;
        itemLink.href = 'javascript:void(0);';
        // itemLink.target = '_blank';
        itemLink.textContent = feature.properties.label || '';
        let ce: any;
        if (
          feature.customCenter &&
          feature.customCenter.geometry &&
          feature.customCenter.geometry.coordinates &&
          feature.customCenter.geometry.coordinates.length == 2
        ) {
          ce = feature.customCenter.geometry.coordinates;
          itemLink.addEventListener('click', () => {
            this.map!.flyTo({
              zoom: 20,
              center: ce!,
              essential: true, // this animation is considered essential with respect to prefers-reduced-motion
            });
          });
        }
        
        this.dataMap.push(feature);
        itemsData.push({ label: itemLink.textContent, center: ce });
      }
      this.accordion.push({ nombre: nombre, lista: itemsData });
    }
  }

  renderListingsFilter(features: any[], dataSinCoord: any[], nombre: string) {
    const epigrafe = document.createElement('h2');
    epigrafe.textContent = nombre;
    this.accordion2 = [];
    if (features.length || dataSinCoord.length) {
      const itemsData = [];
      for (const newItem of dataSinCoord) {
        const itemLink = document.createElement('a');
        itemLink.href = 'javascript:void(0);';
        itemLink.textContent = newItem.properties.label || '';
        itemLink.style.cssText = 'color: #000000; !important';
        itemsData.push({
          label: itemLink.textContent,
          center: null,
          id: newItem.id,
          sign_archivo: newItem.properties.sign_archivo,
          epigrafeId: newItem.properties.epigrafeId,
          n_orden: newItem.properties.n_orden,
        });
      }
      for (const feature of features) {
        const itemLink = document.createElement('a');
        itemLink.href = 'javascript:void(0);';
        itemLink.textContent = feature.properties.label || '';
        let ce: any;
        if (
          feature.customCenter &&
          feature.customCenter.geometry &&
          feature.customCenter.geometry.coordinates &&
          feature.customCenter.geometry.coordinates.length == 2
        ) {
          ce = feature.customCenter.geometry.coordinates;
          itemLink.addEventListener('click', () => {
            this.map!.flyTo({
              zoom: 20,
              center: ce!,
              essential: true,
            });
          });
        }
        itemsData.push({ label: itemLink.textContent, center: ce });
      }
      this.accordion2 = [{ nombre: nombre, lista: itemsData }];
    }
  }

  fijarCentro(item: any) {
    if (item.center) {
      this.map!.flyTo({
        zoom: 20,
        center: item.center,
        essential: true, // this animation is considered essential with respect to prefers-reduced-motion
      });
    } else {
      if (!this.epl.includes(item.epigrafeId)) this.openDialog(item);
    }
  }

  dibujarActualizarPoligono() {
    this.actualizarPoligono = true;
    this.map!.addControl(this.draw);
  }

  finUpdatePoligono() {
    this.actualizarPoligono = false;
    this.map!.removeControl(this.draw);
    this.openDialog(this.updateItemData);
  }

  actualizarSourceMap(item: any) {
    try {
      let idLayer = item.epigrafeId;
      const stores: any = this.map!.getSource(`source-${idLayer}`);
      console.log(item);
      // this.map!.getSource('source-iu').querySourceFeatures('source-iu');
      const data = stores._data;
      let listaLtLng: any[] = item.geom.coordinates[0][0];
      let label = `Nro: ${item.n_orden} - ${item.nombre || ''}`;
      if(idLayer == 18 || idLayer == 19 ){
        label = `Nro: ${item.n_orden} - ${item.denominacion || ''}`;
      } else if (idLayer == 4 ){
        label = `Nro: ${item.n_orden} - ${item.descripcion || ''}`;
      }
      var center = undefined;
      let coordAdd = [listaLtLng];
      let tipoGeometry = TipoGeo.POLILINNEA;
      let geomAdd = {
        // type: 'Polygon',
        type: tipoGeometry,
        coordinates: coordAdd,
      };
      if (item.type == TipoGeo.MULTILINE) {
        tipoGeometry = TipoGeo.MULTILINE;
        listaLtLng = item.geom.coordinates[0];
        coordAdd = listaLtLng;
        geomAdd = item.geom;
      }
      //nuevo
      var center = undefined;
      // let coordAdd = [listaLtLng];
      if (item.geom && item.geom.type == TipoGeo.POLILINNEA) {
        listaLtLng = item.geom.coordinates;
        coordAdd = listaLtLng;
        let poly2 = turf.polygon(listaLtLng);
        center = turf.center(poly2);
      } else if (item.geom && item.type == TipoGeo.MULTILINE) {
        listaLtLng = item.geom.coordinates;
        let poly2 = turf.multiLineString(listaLtLng);
        center = turf.center(poly2);
      } else if (item.type == TipoGeo.POLILINNEA) {
        let poly2 = turf.polygon([listaLtLng]);
        center = turf.center(poly2);
      } else if (item.type == TipoGeo.MULTIPOLYGON) {
        const coordinates = turf.getCoords(item.geom);
        const startPoint = turf.point(coordinates[0][0][0]);
        const endPoint = turf.point(coordinates[0][0][0]);
        center = turf.midpoint(startPoint, endPoint);
      }
      ////nuevo
      data.features.push({
        id: item.id,
        customCenter: center,
        properties: {
          label,
          name: 'NUEVO',
          ...item,
        },
        geometry: item.geom,
      });

      this.map!.removeLayer(`layer-${idLayer}`);
      this.map!.removeSource(`source-${idLayer}`);
      this.map!.addSource(`source-${idLayer}`, {
        type: 'geojson',
        data: {
          type: 'FeatureCollection',
          features: data.features,
        },
      });

      const itemlayer = this.epigrafesList.find((e) => e.id == idLayer);
      this.hoveredStateId = null;
      this.addLayer(itemlayer);
      // if (
      //   itemlayer.type == TipoGeo.POLILINNEA ||
      //   itemlayer.type == TipoGeo.MULTIPOLYGON
      // ) {
      //   this.map!.addLayer({
      //     id: `layer-${itemlayer.id}`,
      //     type: 'fill',
      //     source: `source-${idLayer}`,
      //     layout: this.layouts[itemlayer.id],
      //     paint: {
      //       // 'fill-color': itemlayer.color,
      //       'fill-color': this.coloresEpigrades[`${itemlayer.id}`],
      //       'fill-opacity': [
      //         'case',
      //         ['boolean', ['feature-state', 'hover'], false],
      //         1,
      //         0.3,
      //       ],
      //     },
      //   });

      //   this.map!.on('mousemove', `layer-${itemlayer.id}`, (e: any) => {
      //     if (e.features.length > 0) {
      //       if (this.hoveredStateId !== null) {
      //         this.map!.setFeatureState(
      //           { source: `source-${itemlayer.id}`, id: this.hoveredStateId },
      //           { hover: false }
      //         );
      //       }
      //       this.hoveredStateId = e.features[0].id;
      //       this.map!.setFeatureState(
      //         {
      //           source: `source-${itemlayer.id}`,
      //           id: this.hoveredStateId || '',
      //         },
      //         { hover: true }
      //       );
      //     }
      //   });
      //   this.map!.on('mouseleave', `layer-${itemlayer.id}`, () => {
      //     if (this.hoveredStateId !== null) {
      //       this.map!.setFeatureState(
      //         { source: `source-${itemlayer.id}`, id: this.hoveredStateId },
      //         { hover: false }
      //       );
      //     }
      //     this.hoveredStateId = null;
      //   });
      // } else {
      //   this.map!.addLayer({
      //     id: `layer-${itemlayer.id}`,
      //     type: 'line',
      //     source: `source-${itemlayer.id}`,
      //     layout: this.layouts[itemlayer.id],
      //     paint: {
      //       'line-width': 5,
      //       // 'line-color': itemlayer.color,
      //       'line-color': this.coloresEpigrades[`${itemlayer.id}`],
      //     },
      //   });
      // }
      // //finaddlayer
    } catch (error) {
      console.log('Error actualizando source map');
      console.log(error);
    }
  }

  openBottomSheet(item: any): void {
    const bottomSheetRef = this._bottomSheet.open(ConfigbottomSheetComponent,{data: this.getColorById(item.id)});
    bottomSheetRef.instance.variableEmitida.subscribe((variable) => {
      this.coloresEpigrades[`${item.id}`] = variable;
      //actualizar colores localStorage
      this.setColores(this.coloresEpigrades);
      //actualizar colores en mapa
      this.map!.removeLayer(`layer-${item.id}`);
      this.addLayer(item);
    });
  }

  epl = [12, 7, 13, 14, 21, 5, 6];

  getColores() {
    const dataStorage = localStorage.getItem('mapColores');
    let data;
    if (dataStorage) {
      data = JSON.parse(dataStorage);
    } else {
      data = {
        8: '#b4a7d6',
        9: '#8fce00',
        10: '#cd4588',
        11: '#797891',
        17: '#FE6F5E',
        18: '#351c75',
        19: '#744700',
        4: '#ebc860',
        20: '#32A0A8',
        li: '#B02C3A',
      };
      this.setColores(data);
    }
    this.coloresEpigrades = data;
  }

  getColorById(id: any){
    return this.coloresEpigrades[`${id}`];
  }

  setColores(data: any){
    localStorage.setItem('mapColores', JSON.stringify(data));
  }
  
  addLayer(itemlayer: any){
    if (
      itemlayer.type == TipoGeo.POLILINNEA ||
      itemlayer.type == TipoGeo.MULTIPOLYGON
    ) {
      this.map!.addLayer({
        id: `layer-${itemlayer.id}`,
        type: 'fill',
        source: `source-${itemlayer.id}`,
        layout: this.layouts[itemlayer.id],
        paint: {
          'fill-color': this.coloresEpigrades[`${itemlayer.id}`],
          'fill-opacity': [
            'case',
            ['boolean', ['feature-state', 'hover'], false],
            1,
            0.3,
          ],
        },
      });

      this.map!.on('mousemove', `layer-${itemlayer.id}`, (e: any) => {
        if (e.features.length > 0) {
          if (this.hoveredStateId !== null) {
            this.map!.setFeatureState(
              { source: `source-${itemlayer.id}`, id: this.hoveredStateId },
              { hover: false }
            );
          }
          this.hoveredStateId = e.features[0].id;
          this.map!.setFeatureState(
            {
              source: `source-${itemlayer.id}`,
              id: this.hoveredStateId || '',
            },
            { hover: true }
          );
        }
      });
      this.map!.on('mouseleave', `layer-${itemlayer.id}`, () => {
        if (this.hoveredStateId !== null) {
          this.map!.setFeatureState(
            { source: `source-${itemlayer.id}`, id: this.hoveredStateId },
            { hover: false }
          );
        }
        this.hoveredStateId = null;
      });
    } else {
      this.map!.addLayer({
        id: `layer-${itemlayer.id}`,
        type: 'line',
        source: `source-${itemlayer.id}`,
        layout: this.layouts[itemlayer.id],
        paint: {
          'line-width': 5,
          'line-color': this.coloresEpigrades[`${itemlayer.id}`],
        },
      });
    }
  }


  //para detectar la letra scape 
  @HostListener('document:keydown.escape', ['$event']) onKeydownHandler(event: KeyboardEvent) {
    this.actualizarPoligono=false;
    this.map!.removeControl(this.draw);
}
}
