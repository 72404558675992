import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { map, Observable, of, switchMap, throwError } from 'rxjs';
import { environment } from '../../environments/environment';

@Injectable({ providedIn: 'root' })
export class FeatureService {

  observacion_modificacion:any="Actualización de la información del bien";

  private urlApi = environment.apiUrl;
  _dataMunicipio: any;
  

  /**
   * Constructor
   */
  constructor(private _httpClient: HttpClient) {}

  get dataMunicipio(): any {
    return this._dataMunicipio;
  }

  set dataMunicipio(data: any) {
    this._dataMunicipio = data;
  }

  // -----------------------------------------------------------------------------------------------------
  // @ Public methods
  // -----------------------------------------------------------------------------------------------------

  /**
   * Sign in
   *
   * @param credentials
   */
  getDatosBienesById(id: number = 3425): Observable<any> {
    // Throw error, if the user is already logged in
    // if (this._authenticated) {
    //   return throwError(() => new Error('User is already logged in.'));
    // }

    return this._httpClient.get(`datos-bienes/${id}`).pipe(
      switchMap((response: any) => {
        // Store the access token in the local storage

        // Set the authenticated flag to true

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  updateBien(id: number, data: {}): Observable<any> {
    // Throw error, if the user is already logged in
    // if (this._authenticated) {
    //   return throwError(() => new Error('User is already logged in.'));
    // }

    return this._httpClient.post(`datos-bienes/${id}`, data).pipe( //eataba con put
      switchMap((response: any) => {
        // Store the access token in the local storage

        // Set the authenticated flag to true

        // Return a new observable with the response
        return of(response);
      })
    );
  }

  update_create_solicitud(id:any,data:any){
    return this._httpClient
    .put<any>(`datos-bienes/${id}`, data)
    .pipe(
      map((resp) => {
        console.log(resp);
        return resp;
      })
    );
  }

  getPathFiles(tipo: any, signArchivo: any, nOrden: any) {
    const urlBack = this.urlApi.split('/api')[0];
    // const urlBack = this.urlApiFiles.split("/api")[0];
    let folder = 'fotografias';
    let tipoArchivo = 'FOTOGRAFIA';
    const codigoEpigrafe = signArchivo.split('-')[0];
    switch (tipo) {
      case 'c':
        folder = 'planos';
        tipoArchivo = 'CROQUIS';
        break;
      case 'd':
        folder = 'docs';
        tipoArchivo = 'DOCUMENTO';
        break;
      default:
        break;
    }
    return (
      this._httpClient
        // .get(`${this.urlApi}/file-manager/folder/arrecife/${folder}`)
        // .get(`${this.urlApi}/file-manager/folder/arrecife/${folder}`)
        .get(`file-manager/folder/provincias/${this._dataMunicipio.provincia.nombre}/${this._dataMunicipio.nombre}/${folder}`)
        .pipe(
          switchMap((response: any) => {
            const resp: any = [];
            response.forEach((element: any) => {
              try {
                const i = element.name.lastIndexOf('.');
                const nombreArchivo = element.name.substring(0, i);
                const splitNombre = nombreArchivo.split('-');
                if (
                  codigoEpigrafe == splitNombre[0] &&
                  splitNombre[1].toLowerCase() == tipo
                ) {
                  const splitArchivos = splitNombre[2].split('.');
                  const numOrden = Number.parseInt(splitArchivos[0]);
                  if (nOrden == numOrden) {
                    element.src = `${urlBack}${element.downloadUrl}`;
                    element.tipo = tipoArchivo;
                    resp.push(element);
                  }
                }
              } catch (error) {}
            });
            return of(resp);
          })
        )
    );
  }

  getDataImage(url: string): Observable<any> {
    url = url.split('.com/api/')[1];
    return this._httpClient
      .get(url, { responseType: 'blob' })
      .pipe(switchMap((response) => this.readFile(response)));
  }

  private readFile(blob: Blob): Observable<string> {
    return Observable.create((obs: any) => {
      const reader = new FileReader();

      reader.onerror = (err) => obs.error(err);
      reader.onabort = (err) => obs.error(err);
      reader.onload = () => obs.next(reader.result);
      reader.onloadend = () => obs.complete();

      return reader.readAsDataURL(blob);
    });
  }

  getFileUrl(path: any) {
    path = path.split('.com/api/')[1];
    return this._httpClient
      .get(`${path}`, {
        responseType: 'blob',
      })
      .pipe(
        switchMap((response: any) => {
          return of(response);
        })
      );
  }

  /**
   *
   */
  getContable() {
    return this._httpClient.get(`contable`).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  getJuridica() {
    return this._httpClient.get(`juridicas`).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  getFuncional() {
    return this._httpClient.get(`funcionales`).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  getEpigrafes() {
    return this._httpClient.get(`epigrafes`).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  getTiposVias() {
    return this._httpClient.get(`tipos-vias`).pipe(
      switchMap((response: any) => {
        return of(response);
      })
    );
  }

  create(data: any): Observable<any> {
    return this._httpClient.post<any>(`datos-bienes`, data).pipe(
      map((resp) => {
        console.log(resp);
        return resp;
      })
    );
  }

  getInfoProvincia(municipioId: string | number): Observable<any> {
    return this._httpClient.get(`municipios/${municipioId}`).pipe(map((resp: any) => {
      this.dataMunicipio = resp.result;
      return resp;
    }));
  }

}
