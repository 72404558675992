import { Component, Inject, OnInit } from '@angular/core';
import {
  UntypedFormBuilder
} from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatSnackBar } from '@angular/material/snack-bar';
import { ActivatedRoute, RouterLinkActive, Router } from '@angular/router';
import { FeatureService } from '../../services/feature.service';
import { AuthService } from '../../auth/auth.service';

@Component({
  selector: 'app-modal-info',
  templateUrl: './modal-info.component.html',
  styleUrls: ['./modal-info.component.scss'],
})
export class ModalInfoComponent implements OnInit {
  
  isViewInitialized = false;

  navLinks = [];
  statusGeom = false;
  newGeom : any;
  
  /*esta variable sirve para gruardar los datos originales de la data, 
  ya que los valores nulos son cambiados por "NO CONSTA", ocasionando errores al querer actualizar*/
  
  constructor(
    @Inject(MAT_DIALOG_DATA) public itemData: any,
    private _formBuilder: UntypedFormBuilder,
    private featureService: FeatureService,
    private _snackBar: MatSnackBar,
    private route: ActivatedRoute,
    private router: Router,
    private authService: AuthService,
    private dialogRef: MatDialogRef<ModalInfoComponent>
  ) {}

  ngOnInit(): void {


    if (this.authService.accessToken) { 
    }

    if (this.itemData.updateGeom) {
      this.statusGeom = true;
      this.newGeom = this.itemData.geom;
    }
    
    this.featureService
      .getDatosBienesById(this.itemData.id)
      .subscribe((data) => {
        console.log(data.result.datoBien);
        this.itemData = data.result.datoBien;
        if (this.statusGeom) {
          this.itemData.geom = this.newGeom;
        }
       /* Object.keys(this.itemData).forEach(key => {
          if ((this.itemData[key] === null || this.itemData[key] === '') && this.itemData[key] != "NO PROCEDE") {
            this.itemData[key] = 'NO CONSTA';
          }
        });*/
      });
  }

  successUpdate(event: any) {
    if (event === true) {
      this.dialogRef.close({
        event: 'successEditar',
        data: {
          status: true,
          itemData: this.itemData
        },
      });
    }
  }

  updatePoligono(event: any) {
    if (event === true) {
      this.dialogRef.close({
        event: 'polygono',
        data: {
          status: true,
          itemData: { id: this.itemData.id, updateGeom: true },
        },
      });
    }
  }
}
